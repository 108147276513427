<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-bullseye"></i> Services</h2>

        <div class="row mt-3 d-flex justify-content-center">
            <div class="pr-5 pl-5 pb-2">
                <b-button-group>
                    <b-button v-b-tooltip.hover title="Create another instance of a service" class="btn btn-success font-weight-bold ml-2" v-text="'Create '" v-b-modal.createServ />
                    <b-button v-b-tooltip.hover title="Stop all services matching the name" class="btn btn-warning font-weight-bold ml-2" v-text="'Stop '" v-b-modal.stopServ />
                    <b-button v-b-tooltip.hover title="Delete all services matching the name" class="btn btn-danger font-weight-bold ml-2" v-text="'Delete '" v-b-modal.deleteServ />
                    <b-button v-b-tooltip.hover title="Increase or decrease the number of services" class="btn btn-primary font-weight-bold ml-2" v-text="'Scale '" v-b-modal.scaleServ />
                    <b-button v-b-tooltip.hover title="Restart the services running on the server" class="btn btn-secondary font-weight-bold ml-2" v-text="'Restart'" v-b-modal.restartServ />
                </b-button-group>
            </div>
        </div>

        <div class="row listing">
            <div class="col-12">
                <div v-if="!scaling" class="row mb-3">
                    <div class="col-6">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4 class="m-0" v-if="workers.length">{{workers.filter((item)=>{ return item.pm2_env.status == 'online'}).length||0}} Active</h4>
                                <h4 class="m-0" v-else>Loading..</h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="card">
                            <div class="card-body text-center">
                                <h4 class="m-0" v-if="workers.length">{{workers.length||0}} Total</h4>
                                <h4 class="m-0" v-else>Loading..</h4>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style="padding-left: 0px; padding-right:0px;" class="col-12 mb-3">
                        <div class="card">
                            <div class="card-body text-center" >
                               <div class="row" v-if="tabs">
                                    <div style="overflow-x: hidden; max-height: 233px !important; white-space: nowrap;" v-for="(ip,index) in tabs" :key="index" class="col-md-6 col-sm-12">
                                            <h5 style="padding-bottom: 10px;">Server [{{index}}]</h5>
                                            <div v-for="(value,name) in groupBy(ip,'name')" :key="name">
                                                <h6 v-if="value.filter(x => x.pm2_env.status === 'online').length > 0">{{name.toUpperCase()}} - {{value.filter(x => x.pm2_env.status === 'online').length}}</h6>
                                            </div>
                                        </div>
                               </div>
                                <div class="row text-center" v-else>
                                   <div class="col-12">
                                       <h4 class="m-0 ">Loading..</h4>
                                   </div>
                               </div>
                            </div>
                        </div>
            </div>
                <div class="row mb-3" v-if="!scaling && (workers && workers.length)" style="background-color:white; margin:2px;">
                    <div class="col-12">
                        <b-table
                            responsive
                            :items="workers"
                            :fields="fields"
                        >
                            <template v-slot:cell(monit.memory)="row">
                                <div>{{row.item.monit.memory|bandwidth}}</div>
                            </template>
                            <template v-slot:cell(pm2_env.status)="row">
                                <div v-if="row.item.pm2_env.status == 'online'" class="text-success">{{row.item.pm2_env.status}}</div>
                                <div v-else class="text-danger">{{row.item.pm2_env.status}}</div>
                            </template>

                            <template v-slot:cell(actions)="row">
                                <b-button  v-b-tooltip.hover title="View output from running services" class="btn btn-dark btn-sm mr-1 font-weight-bold" @click="servicesStreamLog(row.item); serviceName = row.item.name;item = row.item;" v-b-modal.singServLogs>Log</b-button>
                                <button v-b-tooltip.hover title="Scale the number of this service" class="btn btn-primary btn-sm mr-1 font-weight-bold" @click.prevent="singleScale(row.item)">Scale</button>
                                <button v-b-tooltip.hover title="Restart all instances of this service" class="btn btn-secondary btn-sm mr-1 font-weight-bold" @click.prevent="restartItem(row.item)">Restart</button>
                                <button v-b-tooltip.hover title="Stop all instances of this service" class="btn btn-secondary btn-sm mr-1 font-weight-bold" @click.prevent="stopItem(row.item)">Stop</button>
                                <button v-b-tooltip.hover title="Delete all instances of this service" class="btn btn-danger btn-sm font-weight-bold" @click.prevent="deleteItem(row.item)">Delete</button>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <create-service v-on:saveService="createService" />
        <stop-service v-on:okStop="stopService" />
        <service-logs :logs="workersLogs" :logger="servicesStreamLog" />
        <scale-service v-on:okScale="scaleService" />
        <restart-service v-on:okRestart="restartService" />
        <delete-service v-on:okDelete="deleteService" />
        <single-log-service v-on:ok="handleOk" :name="serviceName" :pid="item" :stream="workersLogs" />
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import { isArray } from 'highcharts';
import createService from "@/components/services/createService";
import stopService from "@/components/services/stopOne";
import serviceLogs from "@/components/services/serviceLogs";
import scaleService from "@/components/services/scaleService";
import restartService from "@/components/services/restartService";
import singleLogService from "@/components/services/singleLogService";
import deleteService from "@/components/services/deleteService";

export default {
    name: "Services",
    props: ['user'],
    components: { createService, stopService, serviceLogs, scaleService, restartService, singleLogService, deleteService },
    data: function () {
        return {
            action:'',
            editor: false,
            item: {},
            serviceName: '',
            refreshData:'off',
            workers: {active:0, total:0, workers: []},
            workersLogs: [],
            preloader: false,
            scaling: false,
            eventworkers: null,
            eventLogs: null,
            timer:true,
            scalingMode: '',
            scaleValue: 35,
            scaleTargets: '',
            processing: false,
            tabs: '',
            counts: '',
            servers: [],
        }
    },
    created: function(){
        // this.load();
        this.$root.preloader = true;
        this.workersLogs = [];
        this.workersLogs.push({"message" :'Fetching log data'});
        this.servicesStream();
    },
    computed: {
        fields() {
          return [
            { key: 'pm_id', label: 'ID', sortable: true, stickyColumn: true },
            { key: 'host', label: 'Host',sortable: true, },
            { key: 'name', label: 'Name', sortable: true, stickyColumn: true },
            { key: 'pm2_env.exec_mode', label: 'Mode' },
            { key: 'pm2_env.restart_time', label: 'Restart' },
            { key: 'monit.memory', label: 'Mem' },
            { key: 'monit.cpu', label: 'Cpu' },
            { key: 'pm2_env.status', label: 'Status', sortable: true },
            // { key: 'pm2_env.watch', label: 'Watch' },
            { key: 'actions', label: ''}
          ]
        },
        getLogData(){
            let data =[];
            return data;
        }
    },
    beforeDestroy: function () {
        if (this.eventworkers) {
            this.eventworkers.close();
            this.eventworkers = null;
        }
        if (this.eventLogs) {
            this.eventLogs.close();
            this.eventLogs = null;
        }
    },
    methods:{
        reload: function(){
            this.load();
        },
        servicesStream: function (){
            if(!this.eventworkers) {
                this.eventworkers = new EventSource(`${this.$root.serverUrl}/static/services/list`, {withCredentials: false});

                this.eventworkers.onerror = (error) => {
                    console.log(`eventworkers.error`, error);
                }
                this.eventworkers.onopen = (args) => {
                    console.log(`eventworkers.open`, args);
                }
                this.eventworkers.onmessage = (event) => {
                    // console.log(`eventworkers.event`, event);
                    try {
                        const parsedData = JSON.parse(event.data);
                        this.workers = parsedData;
                        this.$forceUpdate();
                        if (parsedData) {
                            this.tabs = this.xanParse(parsedData);
                            this.counts = this.serviceCnt(parsedData);
                        }
                    } catch (e) {
                        console.log(`eventworkers.catch: ${e.message}`);
                    }
                };
            }
            this.$root.preloader = false;
        },
        servicesStreamLog: function (worker){

            if (this.eventLogs) {
                this.eventLogs.close();
                this.eventLogs = null;
            }
            if(!this.eventLogs) {
                let request = new URLSearchParams({
                apikey: this.$root.apikey,
                name: worker.name?worker.name:'',
                id: worker.pid?worker.pid:'',
                host:worker.host?worker.host:'',
                args:worker.args ?worker.args:''
                });
                this.eventLogs = new EventSource(`${this.$root.serverUrl}/static/services/log?${request}`, {withCredentials: false});

                this.eventLogs.onerror = (error) => {
                    console.log(`eventLogs.error`, error);
                }
                this.eventLogs.onopen = (args) => {
                    console.log(`eventLogs.open`, args);
                }
                this.eventLogs.onmessage = (event) => {
                    console.log(`eventLogs.event`, event);
                    try {
                        const parsedData = JSON.parse(event.data);
                        // this.workersLogs = parsedData;
                        // console.log(`eventLogs.event:data`,parsedData);
                        this.setLogData(parsedData);
                        this.$forceUpdate();
                    } catch (e) {
                        console.log(`eventLogs.catch: ${e.message}`);
                    }
                };
            }
        },
        setLogData(data){
            if(isArray(this.workersLogs)){
                this.workersLogs.push(data);
                if(this.workersLogs.length > 200){
                    this.workersLogs.shift();
                }
            }
        },
        handleItem(){
            if (this.eventworkers) {
                this.eventworkers.close();
                this.eventworkers = null;
            }
        },
        handleOk(){
            if (this.eventLogs) {
                this.eventLogs.close();
                this.eventLogs = null;
                this.workersLogs = [];
                this.workersLogs.push({"message" :'Fetching log data'});

            }
        },
        load: function(){
            //Load the catchers
            var request = {'apikey': this.$root.apikey};

            this.$root.preloader = true;
            this.workersLogs = [];
            this.workersLogs.push({"message" :'Fetching log data'});

            return axios.get(`${this.$root.serverUrl}/static/services/list`, {params:request}).then((resp)=>{
                this.workers = resp.data || {active:0, total:0, workers:[]}
                this.$root.preloader = false;
            });
        },
        moniter: function(){
            // this.load().finally(function(){
            //     if(this.timer)
            //         setTimeout(this.moniter, 15000 );
            // }.bind(this));
        },
        createService(data) {
            console.log(`Creating service: `, data );

            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                name: data && data.name ? data.name.replaceAll(' ', '_'):'',
                service: data && data.type ? data.type:'smart.js',
                path: data && data.path ? data.path:'crawler/services/',
                args: data && data.args ? data.args:'',
                mode: data && data.mode ? data.mode:1,
                host: data && data.host ? data.host:'10.111.70.43',
                });

                console.log('save pm item: ',`${this.$root.serverUrl}/static/services/start?${request}`);

                axios.post(`${this.$root.serverUrl}/static/services/start?${request}`).then((resp) => {
                    console.log(`Creating service processed`, resp);
                    swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                    //this.load();
                    //this.scaling = false;
                }).catch((err) => {
                    console.log(`Creating service error: ${err.message}`, err.stack);
                    swal.fire({title: 'Error', text: err.message, icon: 'error'});
                }).finally(() => {
                    this.processing = false;
                    //this.scaleTargets = null;
                })
        },
        stopService(data) {
            console.log(`Stopping service: `, data );
            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                service: data && data.name ? data.name:'all',
                host: data && data.host ? data.host:'',
                });
                console.log('save pm item: ',`${this.$root.serverUrl}/static/services/start?${request}`);

                axios.post(`${this.$root.serverUrl}/static/services/stop?${request}`).then((resp) => {
                    console.log(`Stopping service processed`, resp);
                    swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                    //this.load();
                    //this.scaling = false;
                }).catch((err) => {
                    console.log(`Stopping service error: ${err.message}`, err.stack);
                    swal.fire({title: 'Error', text: err.message, icon: 'error'});
                }).finally(() => {
                    this.processing = false;
                    //this.scaleTargets = null;
                })
        },
        scaleService(data) {
            console.log(`Scaling service: `, data );
            let item = {
                name: data.name,
                host: '',                
            };
            let max = Number(data.value);
            console.log(`Scaling service:item `, item , max);
            this.scale(item, max)
        },
        deleteService(data) {
            console.log(`Scaling service: `, data );
            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                service: data && data.name ? data.name:'all',
                host: data && data.host ? data.host:'',
            });
            console.log('save pm item: ',`${this.$root.serverUrl}/static/services/start?${request}`);

            axios.post(`${this.$root.serverUrl}/static/services/delete?${request}`).then((resp) => {
                console.log(`Deleteing service processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                //this.load();
                //this.scaling = false;
            }).catch((err) => {
                console.log(`Deleteing service error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(()=>{
                this.processing = false;
                //this.scaleTargets = null;
            })
        },
        restartService(data) {
            console.log(`Restarting service: `, data );
            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                service: data && data.name ? data.name:'all',
                host: data && data.host ? data.host:'',
            });
            console.log('save pm item: ',`${this.$root.serverUrl}/static/services/start?${request}`);

            axios.post(`${this.$root.serverUrl}/static/services/restart?${request}`).then((resp) => {
                console.log(`Stopping service processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                //this.load();
                //this.scaling = false;
            }).catch((err) => {
                console.log(`Stopping service error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(() => {
                this.processing = false;
                //this.scaleTargets = null;
            })
        },
        stopItem: function(item, single = true){
            //Stop the pitchers on each or all (single = false)
            //this.$root.preloader = true;
            this.processing = true;

            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                pid: item && single && item.pm_id ? item.pm_id:item.name,
                host: item && item.host ? item.host:'10.111.70.43',
            });
            axios.post(`${this.$root.serverUrl}/static/services/stop?${request}`,).then((resp)=>{
                console.log(`Stoping processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                // this.load();
                // this.scaling = false;
            }).catch((err)=>{
                console.log(`Stoping error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(() => {
                this.processing = false;
                //this.scaleTargets = null;
            })
        },
        restartItem: function(item, single = true){
            //Restart the workers on each catcher
            //this.$root.preloader = true;
            //this.processing = true;
            //let urls = [];
            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                pid: item && single && item.pm_id ? item.pm_id:item.name,
                host: item && item.host ? item.host:'10.111.70.43',
            });
            axios.post(`${this.$root.serverUrl}/static/services/restart?${request}`).then((resp) => {
                console.log(`Restarting processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                //this.load();
                //this.scaling = false;
            }).catch((err) => {
                console.log(`Restarting error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(() => {
                //this.processing = false;
                //this.scaleTargets = null;
            })
        },
        deleteItem: function(item, single = true){
            //Restart the workers on each catcher
            //this.$root.preloader = true;
            this.processing = true;
            //let urls = [];
            let request = new URLSearchParams({
                apikey: this.$root.apikey,
                pid: item && single && item.pm_id ? item.pm_id:item.name,
                host: item && item.host ? item.host:'10.111.70.43',
            });
            axios.post(`${this.$root.serverUrl}/static/services/delete?${request}`).then((resp) => {
                console.log(`Deleteing processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                //this.load();
                //this.scaling = false;
            }).catch((err) => {
                console.log(`Deleteing error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(() => {
                this.processing = false;
                //this.scaleTargets = null;
            })
        },
        scale: function(item, max = 1){
            //Scale the workers on each catcher

            //this.$root.preloader = true;
            this.processing = true;

            let request = {
                apikey: this.$root.apikey,
                host: item && item.host ? item.host:'',
                service: item && item.name ? item.name:'smart',
                pid: item && item.pid ? item.pid:'0',
                max:max
            };

            axios.post(`${this.$root.serverUrl}/static/services/scale`, request).then((resp)=>{
                console.log(`Scaling processed`, resp);
                swal.fire({title: 'Processed', text: 'Your request was submitted for processing.', icon: 'success'});
                // this.load();
                this.scaling = false;
            }).catch((err)=>{
                console.log(`Scaling error: ${err.message}`, err.stack);
                swal.fire({title: 'Error', text: err.message, icon: 'error'});
            }).finally(()=>{
                this.processing = false;
            })
        },
        singleScale(worker) {
            console.log(`Scaling worker`, worker);
            let _val = this.workers.filter(item =>{
                return item.name == worker.name && item.host == worker.host
            });
            _val = _val.length;
            swal.fire({
                title: 'Number of workers',
                input: 'range',
                inputAttributes: {
                    autocapitalize: 'off',
                    min: 1,
                    max: (worker.host.match(/10\.111\.70/))? 25 : 25,
                    step: 1
                },
                inputValue: _val,
                showCancelButton: true,
                confirmButtonText: 'Scale',
                showLoaderOnConfirm: true,
                allowOutsideClick: true
            }).then((result) => {
                if (result.isConfirmed) {
                    this.scaleTargets = worker.ip;
                    this.scaleValue = Number(result.value||1);
                    this.scale(worker, Number(result.value||1));
                }
            });
        },
        xanParse(data) {
            let datah = data.reduce((datah, res) => {
                (datah[res.host] = datah[res.host] || []).push(res);
                return datah;
            }, {})

            return datah
        },
        serviceCnt(data) {
            return data.reduce((service, data) => {
                if (!service[data.name]) {
                    service[data.name] = 1 || [];
                } else {
                    service[data.name] = service[data.name] + 1;
                }
                return service;
            }, {});
        },
        groupBy(arr, property) {
            return arr.reduce(function (memo, x) {
                if (!memo[x[property]]) { memo[x[property]] = []; }
                memo[x[property]].push(x);
                return memo;
            }, {});
        }
    }
}
</script>

<style scoped>
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 1px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
</style>
