<template>
    <b-modal
        id="scaleServ"
        title="Scale Service"
        no-close-on-esc
        no-close-on-backdrop
        centered
        size="sm"
    >
        <div class="modal-body">
            <div class="row">
                <div class="col-12 col-md-6">
                    <label>Service Name</label>
                    <b-form-input class="form-control" v-model="service.name" type="text" placeholder="smart" />
                </div>
                <div class="col-12 col-md-6">
                    <label>Scale By: <strong>{{ service.value }}</strong></label>
                    <b-form-input class="my-auto" v-model="service.value" :disabled="service.name.length < 3" type="range" min="1" max="25" placeholder="smart" />
                </div>
            </div>

        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="success" @click="run()">
                OK
            </b-button>
            <b-button size="sm" @click="canceled">
                Cancel
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "scaleService",
    data() {
        return {
            service: { name: '', value: 1 }
        }
    },
    methods: {
        canceled() {
            this.service = { name: '' };
            this.$root.$emit('bv::hide::modal', 'scaleServ');
        },
        run() {
            this.$emit('okScale', this.service)
            this.service = { name: '', value: 1 };
            this.$root.$emit('bv::hide::modal', 'scaleServ');
        },
    }
}
</script>

<style scoped>

</style>
