<template>
    <b-modal
        id="createServ"
        title="Create Service"
        no-close-on-esc
        no-close-on-backdrop
        centered
        size="lg"
    >
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <b-form-input v-model="service.name" placeholder="smart" />
                </div>
                <div class="col-6">
                    <b-form-select v-model="service.type" id="services" @change="triggerPop" v-b-popover.right.manual="displayDescr" :title="service.type" :options="service_types" />
                </div>
            </div>
            <div class="row mt-3" v-if="service.type === 'smart.js'">
                <div class="col-12">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="targeting"
                        name="checkbox-1"
                        value="yes"
                        unchecked-value="no"
                    >
                        Do you want to Target Cities/States?
                    </b-form-checkbox>
                </div>
            </div>
            <div class="row mt-3" v-if="service.type === 'custom'">
                <div class="col-12">
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="custom"
                        name="checkbox-1"
                        value="yes"
                        unchecked-value="no"
                    >
                        Do you want to run custom service script?
                    </b-form-checkbox>
                </div>
            </div>
            <hr v-if="targeting === 'yes'" />
            <div class="row" v-if="targeting === 'yes'">
                <div class="col-6">
                    <label>State</label>
                    <b-form-select v-model="service.state" @change="loadCities" :options="states" />
                </div>
                <div class="col-6">
                    <label>City</label>
                    <b-form-select v-model="service.city" :options="cities" value-field="city_name" text-field="city_name" />
                </div>
            </div>
            <hr v-if="custom === 'yes'" />
            <div class="row" v-if="custom === 'yes'">
                <div class="col-6">
                    <b-form-input v-model="service.type" placeholder="smart.js" />
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-6">
                    <label>Scale</label>
                    <b-form-select v-model="service.mode" :options="scales"  />
                </div>

                <div class="col-6">
                    <label>Custom Parameters</label>
                    <b-form-input v-model="service.parameters" />
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <label>Host</label>
                    <b-form-select v-model="service.host" :options="hosts" value-field="host" text-field="host" />
                </div>
            </div>
        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="success" @click="save()">
                OK
            </b-button>
            <b-button size="sm" @click="cancelled">
                Cancel
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import axios from "axios";

export default {
    name: "createService",
    data() {
        return {
            country: 'US',
            states: [],
            cities: [],
            scales: [1,2,3,4,5,6,7,8,9,10],
            hosts: [
                {text:'Master:43', host:'10.111.70.43'},
                {text:'Backup:44', host:'10.111.70.44'},
                {text:'Test:40', host:'10.111.40.11'},
            ],
            targeting: 'no',
            custom: 'no',
            service: { type: '', state: '', city: '', path: 'crawler/services/', args:'', mode: 1, host:'10.111.70.43', parameters:''},
            service_types: [
                { text: 'Select Service', value: '', disabled: true}
            ]
        }
    },
    mounted() {
        this.loadStates()
        this.loadService()
    },
    computed: {
        displayDescr(value) {
            return value
        }
    },
    methods: {
        cancelled() {
            this.service = { type: '', state: '', city: '', path: 'crawler/services/', args:'', mode: 1, host:'10.111.70.43' };
            this.$root.$emit('bv::hide::modal', 'createServ');
        },
        save() {
            let args = '';
            args += this.service.state && this.service.state.length > 0?`--state ${this.service.state.replace(' ','_')} `:'';
            args += this.service.city && this.service.city.length > 0?`--city ${this.service.city.replace(' ','_')} `:'';
            args += this.service.parameters && this.service.parameters.length > 0?`${this.service.parameters} `:'';
            // if(this.service.state)
            this.service.args = args;
          this.$emit('saveService', this.service);
        },
        getDesc(desc) {
            console.log(desc)
            return desc
        },
        triggerPop(event) {
            this.service_types.forEach((data) => {
                if (event === data.value) {
                    this.$nextTick(() => {
                        this.displayDescr = data.description
                        this.$root.$emit('bv::show::popover', 'services')
                    })
                }
            })
        },
        loadStates() {
            this.states = [];
            let request = { filters:{} };

            request.filters.country_iso_code = this.country;

            axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then( (resp) => {
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            });
        },
        loadService() {
            this.service_types = [{ text: 'Select Service', value: '', disabled: true}]

            axios.get(`${this.$root.serverUrl}/admin/serviceList`, {}).then( (resp) => {
                for(let i = 0; i < resp.data.data.data.length; i ++) {
                    this.service_types.push(resp.data.data.data[i]);
                }
            });
        },
        loadCities(){
            this.cities = [];
            let request = { filters:{} };

            request.filters.country_iso_code = this.country;
            request.filters.subdivision_1_iso_code = this.service.state;

            axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then( (resp) => {
                this.cities = (resp.data)? resp.data.data : [];
            });
        },
    }
}
</script>

<style scoped>

</style>
