<template>
    <b-modal
        id="restartServ"
        title="Restart Service"
        no-close-on-esc
        no-close-on-backdrop
        centered
        size="sm"
    >
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label>Service Name</label>
                    <b-form-input class="form-control" v-model="service.name" type="text" placeholder="smart" />
                </div>
            </div>

        </div>
        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="success" @click="run()">
                OK
            </b-button>
            <b-button size="sm" @click="canceled">
                Cancel
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "restartService",
    data() {
        return {
            service: { name: '' }
        }
    },
    methods: {
        canceled() {
            this.service = { name: '' };
            this.$root.$emit('bv::hide::modal', 'restartServ');
        },
        run() {
            this.$emit('okRestart', this.service)
            this.service = { name: '' };
            this.$root.$emit('bv::hide::modal', 'restartServ');
        },
    }
}
</script>

<style scoped>

</style>
