<template>
    <b-modal
        id="singServLogs"
        :title="`Service Logs - ${pid.name}, ID:${pid.pm_id}, Host:${pid.host}`"
        modal-class="sizeOverride"
        body-class="p-0 backGround"
        no-close-on-esc
        no-close-on-backdrop
        scrollable
        centered
        size="xl"
    >

        <div class="modal-body backGround">
            <div class="row">
                <div class="col-12">
                    <p class="m-0 message" v-for="(str, index) in stream" :key="index">
                        <!-- <strong class="labels">app_name: </strong>{{str.app_name}} -->
                        <strong class="labels"> message: </strong>{{str.message}}
                    </p>
                </div>
            </div>
        </div>

        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="success" @click="run()">
                OK
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "singleLogService",
    props: ['stream', 'name', 'pid'],

    data() {
        return { }
    },
    methods: {
        run() {
            this.$emit('ok')
            this.$root.$emit('bv::hide::modal', 'singServLogs');
        },
    }
}
</script>

<style scoped>
    .backGround {
        background-color: #044751 !important;
    }
    .message {
        color: #9BC6A7 !important;
    }
    .labels {
        color: #fdc46b !important;
    }
    /deep/ .modal-dialog {
        min-width: 95% !important;
        min-height: 700px !important;
        max-height: 700px !important;
    }
</style>
