<template>

    <b-modal
        id="servLogs"
        title="Service Logs"
        modal-class="sizeOverride"
        body-class="p-0 backGround"
        no-close-on-esc
        no-close-on-backdrop
        centered
        size="xl"
    >
        <template #modal-header>
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-auto my-auto col-md-auto" style="border-radius: .95rem; background-color: rgb(220, 220, 220, .5);">
                        <div class="btn-group btn-group-sm" role="group" aria-label="Solid button group">
                            <button type="button" v-b-popover.hover.left="'Unfiltered log from server.'" title="General Log" :class="{ 'btn-info' : logChoice === 'general' }" class="btn dateBtnCorners font-weight-bold font-size-sm mr-2">
                                <span>General Log </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                            <button type="button" v-b-popover.hover.top="'Displays need for all running services.'" title="Need"  :class="{ 'btn-info' : logChoice === 'need' }" class="btn dateBtnCorners font-weight-bold font-size-sm mr-2">
                                <span>Need </span>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="modal-body backGround">
            <div class="row">
                <div class="col-12">
                    <p class="m-0 message" v-for="(str, index) in logs" :key="index"><strong class="labels">message: </strong>{{str.message}}</p>
                </div>
            </div>
        </div>

        <template #modal-footer="{ ok, cancel, hide }">
            <b-button size="md" variant="success" @click="done()" class="font-weight-bold">
                DONE
            </b-button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "logs",
    data() {
        return {
            logChoice: 'need'
        }
    },
    props: ['logs','logger'],
    methods: {
        done() {
            this.$root.$emit('bv::hide::modal', 'servLogs');
        }
    }
}
</script>

<style scoped>
    .dateBtnCorners {
        border-radius: .95rem !important;
        padding: .26rem .90rem;
    }
    .backGround {
        background-color: #044751 !important;
    }
    .message {
        color: #9BC6A7 !important;
    }
    .labels {
        color: #fdc46b !important;
    }
    /deep/ .modal-dialog {
        min-width: 95% !important;
        min-height: 700px !important;
        max-height: 700px !important;
    }
</style>
